//
// Breadcrumb custom
// --------------------------------------------------

// Breadcrumb Classic
// -------------------------

.breadcrumb-classic {
	position: $breadcrumb-classic-position;

	& {
		@include bg-variant-custom(linear-gradient(90deg, $brand-picton-blue 0%, $brand-dollar-bill 100%), $brand-picton-blue);
	}

	&:before {
		@include bg-variant-custom(linear-gradient(270deg, $brand-picton-blue 0%, $brand-dollar-bill 100%), $brand-picton-blue);
	}

	&, a {
		color: $breadcrumb-classic-color;
	}

	&:before {
		position: $breadcrumb-classic-after-position;
		top: $breadcrumb-classic-after-position-right;
		left: $breadcrumb-classic-after-position-left;
		width: $breadcrumb-classic-after-width;
		height: $breadcrumb-classic-after-height;
		content: '';
		animation: $breadcrumb-classic-animation;
	}

	& > *:not(svg) {
		position: relative;
		z-index: 1;
	}

	.svg-triangle-bottom {
		position: absolute;
		top: 100%;
		left: 50%;
		width: 60px;
		height: 30px;
		margin-top: -1px;
		margin-left: -30px;
		z-index: 2;
	}
}

// Breadcrumb Default
// -------------------------

.breadcrumb-default {
	padding: $breadcrumb-default-inset;
	background: $breadcrumb-default-background;

	> li {
		display: inline-block;

		a {
			color: $breadcrumb-default-color;
			font-size: $breadcrumb-default-size;

			&:hover {
				color: $breadcrumb-default-hover;
			}
		}

		& + li {
			margin-left: $breadcrumb-default-offset-left;

			&:before {
				content: $breadcrumb-default-icon;
				font-family: $breadcrumb-default-icon-font-family;
				color: $breadcrumb-default-icon-color;
				padding-right: $breadcrumb-default-icon-inset-right;
			}
		}
	}
}

// Breadcrumb Modern
// -------------------------

.breadcrumb-modern {
	background: linear-gradient( 0deg, $brand-primary 0%, $brand-java 100% );

	.list-inline li a {
		color: $white;
		opacity: .6;
		transition: opacity .25s;

		&:hover {
			color: $white;
			opacity: 1;
			text-decoration: none;
		}
	}
}

// Bootstrap Breadcrumb
// -------------------------

.breadcrumb {
}

// Breadcrumb inset
// -------------------------

@media (min-width: $screen-md-min) {
	.breadcrumb-inset-left {
		.breadcrumb-modern .shell {
			padding-left: 414px;
		}
	}
}
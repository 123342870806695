//
// Custom typography
// --------------------------------------------------

// Page header
// -------------------------

.page-header {
	padding-bottom: 14px;
}

// Fonts
// -------------------------

.font-default {
	font-family: $font-family-base;
}

.font-accent {
	font-family: $font-family-accent;
}

// Basic Typography
// -------------------------

h1,
.h1 {
	font-size: 36px;
	line-height: 1.5;
	letter-spacing: 0.04em;
	font-family: $font-family-accent;
	text-transform: uppercase;

	@media (min-width: $screen-sm-min) {
		font-size: 60px;
		line-height: 1.2;
	}

	@media (min-width: $screen-md-min) {
		line-height: $font-lh-h1;
		font-size: $font-size-h1;
	}
}

h2,
.h2 {
	font-size: 32px;
	line-height: 1.5;
	letter-spacing: 0.02em;
	font-family: $font-family-accent;
	text-transform: uppercase;

	@media (min-width: $screen-sm-min) {
		font-size: 52px;
		line-height: 1.2;
	}

	@media (min-width: $screen-md-min) {
		line-height: $font-lh-h2;
		font-size: $font-size-h2;
	}
}

h3,
.h3 {
	font-size: 28px;
	line-height: 1.5;
	letter-spacing: 0.03em;
	font-family: $font-family-accent;
	text-transform: uppercase;

	@media (min-width: $screen-sm-min) {
		font-size: 44px;
	}

	@media (min-width: $screen-md-min) {
		line-height: $font-lh-h3;
		font-size: $font-size-h3;
	}
}

h4,
.h4 {
	font-size: 24px;
	line-height: 1.5;

	* + .icon,
	.icon + * {
		padding-left: 8px;
	}

	@media (min-width: $screen-sm-min) {
		line-height: $font-lh-h4;
		font-size: $font-size-h4;
	}
}

h5,
.h5 {
	font-size: 20px;
	line-height: 1.5;

	@media (min-width: $screen-sm-min) {
		line-height: $font-lh-h5;
		font-size: $font-size-h5;
	}
}

h6,
.h6 {
	font-size: 17px;
	line-height: 1.5;
	letter-spacing: 0.06em;
	font-family: $font-family-accent;
	text-transform: uppercase;

	@media (min-width: $screen-sm-min) {
		line-height: $font-lh-h6;
		font-size: $font-size-h6;
	}
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
	margin-top: 0;
	margin-bottom: 0;

	a {
		transition: .2s all ease-in;
	}

	a:hover {
		color: $brand-java;
	}

	.small {
		color: $gray-dark;
	}
}

p,
.p,
.list {
	margin-bottom: 10px;

	a:hover {
		text-decoration: underline;
	}
}

@media (min-width: $screen-xs) {
	.big {
		font-size: 106.25%;
		line-height: 1.470588235294118;

		h1 &,
		.h1 & {
			font-size: 142.22%;
			line-height: 1.125;
		}

		h2 &,
		.h2 & {
			font-size: 147.36%;
			line-height: 1.142857142857143;
		}
	}
}

blockquote small,
blockquote .small,
small,
.small {
	font-size: 93.75%;

	&:before {
		display: none;
	}

	h1 &,
	.h1 & {
		font-size: 62.22222222222222%;
	}

	h2 &,
	.h2 & {
		font-size: 63.15789473684211%;
	}

	h3 &,
	.h3 & {
		font-size: 73.33333333333333%;
	}

	h4 &,
	.h4 & {
		font-size: 75%;
	}

	h5 &,
	.h5 & {
		font-size: 72.72727272727273%;
	}

	h6 &,
	.h6 & {
		font-size: 94.11764705882353%;
	}
}

sub {
	bottom: -0.5em;
}

sub, sup {
	font-size: 62%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

code, kbd, pre, samp {
	font-family: Consolas, "Courier New", monospace;
}

code {
	padding: 5px 7px;
	font-size: 75%;
	color: #E50A0A;
	background-color: $gray-lightest;
	border-radius: 2px;
}

pre {
	padding: 16px 19px;
	margin-bottom: 0;
	font-size: 16px;
	line-height: 1.13;
	overflow-x: auto;

	code {
		font-size: 75%;
		text-align: left;
	}
}

mark, .mark {
	color: $white;
	background-color: $brand-primary;
	padding: .1em .3em;
}

.text-extra-small {
	font-size: 13px;
}

.text-extra-big {
	font-size: 100px;
	line-height: 1;

	@media (min-width: $screen-sm-min) {
		font-size: 140px;
	}
	@media (min-width: $screen-md-min) {
		font-size: 180px;
	}
	@media (min-width: $screen-lg-min) {
		font-size: 240px
	}
}

// Lists
// -------------------------

.list {
	> li + li {
		margin-top: 10px;
	}
}

.list-numbered {
	padding-left: 47px;

	li {
		color: $gray;
		padding-left: 10px;
		span {
			color: $text-color;
		}
	}
}

// List inline
//
.list-inline {
	margin-left: -5px;
	margin-right: -5px;

	> li {
		padding-left: 5px;
		padding-right: 5px;
	}
}

.list-inline-xs {
	margin-left: -3px;
	margin-right: -3px;

	> li {
		padding-left: 3px;
		padding-right: 3px;
	}
}

.list-inline-sm {
	margin-left: -10px;
	margin-right: -10px;

	> li {
		padding-left: 10px;
		padding-right: 10px;
	}
}

.list-inline-md {
	margin-left: -15px;
	margin-right: -15px;

	> li {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.list-inline-lg {
	margin-left: -30px;
	margin-right: -30px;

	> li {
		padding-left: 30px;
		padding-right: 30px;

		&:first-child {
			padding-left: 0;
		}

		&:before {
			color: $gray;
		}

		& + li {
			[class*='fa-']:before {
				margin-left: 15px;
			}
		}
	}

	@media (min-width: $screen-sm-min) {
		li + li {
			[class*='fa-']:before {
				margin-left: 0;
			}
		}

		[class*='fa-']:before {
			padding-left: 15px;
			padding-right: 10px;
		}
	}
}

.list-inline-picton-blue {
	> li > a {
		transition: .3s;
		&:hover {
			color: $brand-picton-blue;
		}
	}
}

.list-inline-white {
	> li > a {
		transition: .3s;
		&:hover {
			color: $white;
		}
	}
}

.list-inline-dashed {
	> li {
		padding-right: 0;
		padding-left: 10px;
		& + li {
			&:before {
				content: '';
				display: none;
				height: 24px;
				width: 1px;
				background: $gray;
				margin-right: 10px;
				position: relative;
				top: 6px;
			}
		}

		@media (min-width: $screen-lg-min) {
			& + li {
				&:before {
					display: inline-block;
				}
			}
		}
	}
}

.list-inline-arrows {
	> li {
		padding-right: 0;

		& + li {
			padding-left: 15px;
			&:before {
				content: '\f14f';
				font-family: 'Material Design Icons';
				font-size: 14px;
				line-height: 14px;
				padding-right: 15px;
				opacity: .4;
			}
		}
	}
}

.list-vertical-right-line {
	li {
		padding: 20px;
		transition: .3s all ease;

		&:hover {
			color: $white;
			background: darken($brand-primary, 10%);
		}
		@media (min-width: $screen-lg-min) {
			padding: 0;
		}
	}

	@media (min-width: 1400px) {
		li {
			position: relative;

			&:after {
				content: '';
				position: absolute;
				top: 50%;
				right: 0;
				width: 1px;
				height: 160px;
				@include transform(translateY(-50%));
				background: rgba($white, .4);
			}
		}
	}
}

.list-inline-dashed-wide {
	> li {
		padding-right: 0;
		& + li {
			&:before {
				content: '/';
				padding-right: 20px;
			}
		}
	}

	@media (min-width: $screen-md-min) {
		> li {
			& + li {
				&:before {
					padding-right: 5px;
				}
			}
		}
	}
}

.list-inline-squared {
	> li {
		position: relative;
		& + li {
			&:before {
				content: '';
				position: absolute;
				top: 50%;
				margin-top: -3px;
				left: -7px;
				height: 6px;
				border-left: 6px solid;
			}
		}
	}

	&.list-inline-mg {
		& + li {
			&:before {
				left: -23px;
			}
		}
	}

	&.list-inline-lg {
		& + li {
			&:before {
				left: -33px;
			}
		}
	}
}

// List terms
//

.list-terms {
	margin-top: 35px;
	dt + dd {
		margin-top: 9px;
	}
	dd + dt {
		margin-top: 62px;
	}
}

// List marked
//

.list-marked {
	@include list-unstyled;
	text-align: left;
	padding-left: 27px;

	li {
		padding-left: 27px;
		position: relative;

		&:before {
			content: '\f238';
			position: absolute;
			font-family: 'Material Design Icons';
			left: 2px;
			top: 13px;
			transform: translateY(-50%);
			color: $brand-java;
		}
	}
}

.list-marked-gray {
	li:before {
		color: $gray-dark;
	}
}

// List index
//
.list-index {
	@include list-unstyled;
	counter-reset: li;

	li {
		.list-index-counter {
			&:before {
				content: counter(li, decimal-leading-zero);
				counter-increment: li;
			}
		}
	}
}

// List separeted
//
.list-separated {
	li + li {
		border-top: 1px solid $gray-lighter;
		margin-top: 8px;
		padding-top: 7px;
	}
}

// Backgrounds
// -------------------------

.bg-fixed {
	background-attachment: fixed;
	background-repeat: no-repeat;
	-webkit-background-size: cover;
	background-size: cover;
}

.bg-dark {
	@extend %context-dark;
}

// Contextual backgrounds
.bg-java {           @include bg-variant-custom(#{$brand-java}); }
.bg-white-liac {     @include bg-variant-custom(#{$brand-white-liac}); }
.bg-primary {        @include bg-variant-custom(#{$brand-primary}); }
.bg-gray-base {      @include bg-variant-custom(#{$gray-base}); }
.bg-gray-darkest {   @include bg-variant-custom(#{$gray-darkest}); }
.bg-gray-darker {    @include bg-variant-custom(#{$gray-darker}); }
.bg-gray {           @include bg-variant-custom(#{$gray}); }
.bg-gray-light {     @include bg-variant-custom(#{$gray-light}) }
.bg-lighter {        @include bg-variant-custom(#{$gray-lighter}); }
.bg-lightest {       @include bg-variant-custom(#{$gray-lightest}); }
.bg-picton-blue {    @include bg-variant-custom(#{$brand-picton-blue}); }
.bg-white {          @include bg-variant-custom(#{$white}); }

.bg-deluge {
	@include bg-variant-custom(#{$brand-deluge});
	@extend %context-dark;
}

.bg-butterfly-bush {
	@include bg-variant-custom(#{$brand-butterfly-bush});
	@extend %context-dark;
}

.bg-cover {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}

// Gradient background
.bg-boston-blue {    @include bg-variant-custom(linear-gradient(90deg, #23d5ae 0%, #3a67b8 100%)); }
.bg-black-haze {     @include bg-variant-custom(linear-gradient(0deg, $gray-lightest 0%, $white 100%)); }
.bg-acapulco {       @include bg-variant-custom(linear-gradient(90deg, $brand-picton-blue 0%, $brand-dollar-bill 100%), $brand-picton-blue); }
.bg-blue-gray {      @include bg-variant-custom(linear-gradient(90deg, $brand-deluge 0%, $brand-picton-blue 100%)); }
.bg-red {            @include bg-variant-custom(linear-gradient(90deg, $brand-danger 0%, $brand-ku-crimson 100%)); }
.bg-mantis {         @include bg-variant-custom(linear-gradient(90deg, $brand-primary 0%, $brand-dollar-bill 100%)); }
.bg-shark {          @include bg-variant-custom(linear-gradient(90deg, $gray-base 0%, $gray-darker 100%)); }
.bg-shark-radio {    @include bg-variant-custom(radial-gradient(ellipse at center, $gray-darkest 0%, $gray-base 100%)); }
.bg-deluge {         @include bg-variant-custom(linear-gradient(90deg, $brand-deluge 0%, $brand-butterfly-bush 100%)); }
.bg-malibu {         @include bg-variant-custom(linear-gradient(90deg, $brand-iceberg 0%, $brand-info 100%)); }
.bg-saffron {        @include bg-variant-custom(linear-gradient(90deg, $brand-warning 0%, $brand-neon-carrot 100%)); }

// Tilt Gradient background
.bg-blue-gray-tilt { @include bg-variant-custom(linear-gradient(45deg, $brand-deluge 0%, $brand-picton-blue 100%)); }
.bg-red-tilt {       @include bg-variant-custom(linear-gradient(45deg, $brand-danger 0%, $brand-ku-crimson 100%)); }
.bg-mantis-tilt {    @include bg-variant-custom(linear-gradient(45deg, $brand-primary 0%, $brand-dollar-bill 100%)); }
.bg-shark-tilt {     @include bg-variant-custom(linear-gradient(45deg, $gray-base 0%, $gray-darker 100%)); }
.bg-deluge-tilt {    @include bg-variant-custom(linear-gradient(45deg, $brand-deluge 0%, $brand-butterfly-bush 100%)); }
.bg-malibu-tilt {    @include bg-variant-custom(linear-gradient(45deg, $brand-iceberg 0%, $brand-info 100%)); }
.bg-saffron-tilt {   @include bg-variant-custom(linear-gradient(90deg, $brand-warning 0%, $brand-neon-carrot 100%)); }

// Overlay background
//

.bg-overlay-white {
	background: rgba($white, .8);
}

.bg-overlay-gray-darkest {
	background: rgba($gray-darkest, .65);
}

@media (min-width: 992px) {
	.bg-image-md-fullwidth {
		position: relative;
	}

	.bg-image-md-fullwidth:before {
		content: '';
		position: absolute;
		top: 0;
		width: 50vw;
		height: 100%;
		background: url(../images/backgrounds/background-02-676x545.jpg);
		background-repeat: no-repeat;
		background-position: top left;
		background-size: cover;
	}

	.bg-image-md-fullwidth-left:before {
		right: 15px;
	}
}

// Colors
// -------------------------

// Contextual colors
//

.page {
	@include text-emphasis-variant('.text-primary', $brand-primary);
	@include text-emphasis-variant('.text-gray-darker', $gray-darker);
	@include text-emphasis-variant('.text-gray-light', $gray-light);
	@include text-emphasis-variant('.text-gray-dark', $gray-dark);
	@include text-emphasis-variant('.text-gray', $gray);
	@include text-emphasis-variant('.text-lighter', $gray-lighter);
	@include text-emphasis-variant('.text-java', $brand-java);
	@include text-emphasis-variant('.text-jungle-green', #212121);
	@include text-emphasis-variant('.text-deluge', $brand-deluge);
	@include text-emphasis-variant('.text-butterfly-bush', $brand-butterfly-bush);
	@include text-emphasis-variant('.text-picton-blue', $brand-picton-blue);
	@include text-emphasis-variant('.text-dollar-bill', $brand-dollar-bill);
	@include text-emphasis-variant('.text-iceberg', $brand-iceberg);
	@include text-emphasis-variant('.text-ku-crimson', $brand-ku-crimson);
	@include text-emphasis-variant('.text-neon-carrot', $brand-neon-carrot);
	@include text-emphasis-variant('.text-darker', $gray-darker, $brand-primary);
	@include text-emphasis-variant('.text-white', $white);
	@include text-emphasis-variant('.text-black', $gray-base);
	@include text-emphasis-variant('.text-dark', $gray-dark);

	.text-white-opacity-02 {
		color: rgba($white, .2);
	}

	.opacity-02 { opacity: .2; }

	a.text-white:hover {
		color: white;
		text-decoration: underline;
	}

	// Contextual colors
	//
	%text-gradient {
		&, &:before {
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}

	.text-boston-blue {
		@extend %text-gradient;

		&, &:before {
			color: #3a67b8;
			background-image: -webkit-linear-gradient(90deg, #3a67b8 0%, #23d5ae 100%);
		}
	}

	.text-mantis {
		@extend %text-gradient;

		&, &:before {
			color: #42B574;
			background-image: -webkit-linear-gradient(0deg, $brand-primary 0%, $brand-dollar-bill 100%);
		}
	}

	.text-acapulco {
		@extend %text-gradient;

		&, &:before {
			color: $brand-picton-blue;
			background-image: -webkit-linear-gradient(0deg, $brand-picton-blue 0%, $brand-dollar-bill 100%);
		}
	}

	.text-blue-gray {
		@extend %text-gradient;

		&, &:before {
			color: rgba(134, 104, 173, 1);
			background-image: -webkit-linear-gradient(0deg, $brand-deluge 0%, $brand-picton-blue 100%);
		}
	}

	.text-malibu {
		@extend %text-gradient;

		&, &:before {
			color: $brand-picton-blue;
			background-image: -webkit-linear-gradient(0deg, $brand-picton-blue 0%, $brand-info 100%);
		}
	}

	.text-carrot {
		@extend %text-gradient;

		&, &:before {
			color: $brand-warning;
			background-image: -webkit-linear-gradient(0deg, $brand-warning 0%, $brand-neon-carrot 100%);
		}
	}

	.text-red {
		@extend %text-gradient;

		&, &:before {
			color: $brand-danger;
			background-image: -webkit-linear-gradient(0deg, $brand-danger 0%, $brand-ku-crimson 100%);
		}
	}

	.text-pink {
		@extend %text-gradient;

		&, &:before {
			color: $brand-deluge;
			background-image: -webkit-linear-gradient(0deg, $brand-deluge 0%, $brand-butterfly-bush 100%);
		}
	}
}

// Shadows
// -------------------------

.shadow-drop-ambient {
	box-shadow: $shadow-area-ambient;
}

.shadow-drop-xxs {
	box-shadow: $shadow-area-xxs;
}

.shadow-drop-xs {
	box-shadow: $shadow-area-xs;
}

.shadow-drop-sm {
	box-shadow: $shadow-area-sm;
}

.shadow-drop,
.shadow-drop-md {
	box-shadow: $shadow-area-md;
}

.shadow-drop-lg {
	box-shadow: $shadow-area-lg;
}

.shadow-drop-xl {
	box-shadow: $shadow-area-xl;
}

.shadow-variant-2 {
	box-shadow: $shadow-area-variant-2;
}

// Borders
// -------------------------

.border-gray-light {
	border-color: $gray-light;
}

.border-gainsboro {
	border-color: $gray;
}

// Address
// -------------------------

.contact-info {
	p {
		font-size: 15px;
		margin-bottom: 0;
	}

	dl {
		margin-bottom: 0;

		dt, dd {
			display: inline-block;
			font-weight: 400;
			font-size: 15px;
		}

		dt, dd a {
			color: $gray-darker;
		}

		dd a:hover {
			color: $brand-picton-blue;
		}
	}
}

// Page footer
// -------------------------

footer {

	.btn-java:focus,
	.btn-java:hover {
		border-color: darken($brand-java, 10%);
		background: darken($brand-java, 10%);
	}

	.footer-brand a {
		display: inline-block;
	}

}

.banner {
	display: inline-block;
}
//
// RD Navbar Default
// --------------------------------------------------

.rd-navbar-default {
  @extend %rd-navbar-default-cart;
  @extend %rd-navbar-transition;

  &.rd-navbar-fixed {
    @extend %rd-navbar-default-panel;
    @extend %rd-navbar-default-mobile-menu;
    @extend %rd-navbar-default-cart-aside-right;
    @extend %rd-navbar-default-aside-left;
    @extend %rd-navbar-default-mobile-search;
    @extend %rd-navbar-default-panel-light;

    .rd-navbar-brand {
      display: none;
    }

    .rd-navbar-brand img {
      max-width: 107px;
    }

    .rd-navbar-search {
      display: none;
    }

    @media (min-width: $screen-xs) {
      .rd-navbar-mobile-brand {
        display: none;
      }
      .rd-navbar-brand {
        display: block;
        @include transform(translateX(-50%));
      }
    }

  }


  &.rd-navbar-static {
    .rd-navbar-mobile-brand {
      display: none;
    }

    .rd-navbar-search-form .form-label{
      top: 44%;
    }

    .rd-navbar-search-form .form-label.focus{
      left: -9px;
      top: 16px;
    }

    .rd-navbar-search-form{
      padding-top: 20px;
    }

    //
    // RD Navbar Default First level of Menu
    // --------------------------------------------------

    .rd-navbar-nav {
      @include clearfix;
      > li {
        float: left;
        position: relative;
        > a {
          @extend %rd-navbar-default-link-font;
          position: relative;
          display: inline-block;
          vertical-align: middle;
          letter-spacing: 0.06em;

          > .rd-navbar-label {
            position: absolute;
            margin: -10px 0 0 -15px;
          }
        }

        &.active > a,
        &.focus > a,
        > a:hover {
          color: $brand-java !important;
        }

        &.rd-navbar--has-megamenu {
          position: static;
        }

        + li {
          margin-left: 37px;
        }
      }

      li.focus {
        > .rd-navbar-dropdown, > .rd-navbar-megamenu {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    //
    // Light Theme
    // --------------------------------------------------

    %rd-navbar-default-menu-first-lvl-light {
      .rd-navbar-nav {
        > li {
          a {
            color: $rd-navbar-light-menu-first-lvl-list-item-link-color;
          }
        }
      }
    }

    @extend %rd-navbar-default-menu-dropdown;
    @extend %rd-navbar-default-search;
    @extend %rd-navbar-default-desktop-cart;

    .rd-navbar-search-form .rd-navbar-search-form-input{
      padding-left: 30px;
      padding-right: 30px;
    }

    .rd-navbar-brand {
      float: left;
      max-width: 103px;

      @media (min-width: $screen-lg-min) {
        max-width: 100%;
      }

      img {
        vertical-align: baseline;
      }
    }

    .rd-navbar-brand-slogan {
      vertical-align: baseline;
      font-size: $rd-navbar-default-slogan-size;
      margin-left: $rd-navbar-default-slogan-offset-left;
    }

    .rd-navbar-menu-wrap {
      float: right;
    }

    .rd-navbar-panel .rd-navbar-toggle, .form-search-wrap {
      display: none;
    }

    .rd-navbar-inner {
      @include clearfix;
      transition: .3s all ease;
      position: relative;
      text-align: left;
      padding: 38px 9px 33px 9px;

      @media (min-width: $screen-lg-min) {
        padding: 38px 20px 33px 20px;
      }

      @media (min-width: $screen-xl-min) {
        padding: $rd-navbar-default-inner-padding;
      }

      &.active {
        .rd-navbar-panel {
          opacity: 0;
          visibility: hidden;
        }
      }
    }

    .rd-navbar-megamenu {
      margin-top: $rd-navbar-default-megamenu-offset-top;
      top: 61%;
    }

    .rd-navbar-nav-wrap, .rd-navbar-panel, .rd-navbar-cart-wrap {
      transition: .3s all ease;
    }

    .rd-navbar-nav-wrap {
      @include clearfix;
      margin-top: 2px;
      display: inline-block;

      .rd-navbar-nav > li + li {
        margin-left: 19px;
      }

      @media (min-width: $screen-lg-min) {
        margin-top: 14px;
        .rd-navbar-nav > li + li {
          margin-left: 25px;
        }
      }

      @media (min-width: 1400px) {
        .rd-navbar-nav > li + li {
          margin-left: 37px;
        }
      }
    }

    .rd-navbar-search-toggle,
    .rd-navbar-cart {
      margin-top: 5px;
    }

    .rd-navbar-search-form-input{
      height: 90%!important;
    }

    .rd-navbar-search-form {
      .rd-input-label,
      input {
        color: $gray-dark!important;
      }
    }

    &.rd-navbar--is-stuck {
      box-shadow: $rd-navbar-default-stuck-shadow;

      .rd-navbar-search-form .form-label.focus{
        left: 13px;
        top: 3px;
      }

      .rd-navbar-megamenu {
        top: 100%;
      }

      .rd-navbar-search-form-input{
        height: 100%!important;
      }

      .rd-navbar-search-form{
        padding-top: 0;
      }

      //.rd-navbar-search-form .rd-navbar-search-form-input{
      //  padding-top: 16px;
      //}

      .rd-navbar-nav > li > .rd-navbar-dropdown {
        margin-top: $rd-navbar-default-stuck-dropdown-offset-top;
      }

      .rd-navbar-megamenu {
        margin-top: 0;
      }
    }

    #rd-search-results-live {
      max-width: 1170px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.rd-navbar--is-stuck {
    .rd-navbar-brand {
      margin-top: 4px;
      img {
        max-width: 100px;
      }
    }
    .rd-navbar-inner {
      padding-top: 17px;
      padding-bottom: 14px;
    }
    .rd-navbar-nav-wrap {
      margin-top: 6px;
    }
  }

  // Themes
  // --------------------------------------------------

  // transparent
  // -------------------------

  &.rd-navbar-transparent {
    #rd-search-results-live{
      display: none!important;
    }

    &.rd-navbar-static {
      .rd-navbar-nav > li a {
        color: $brand-primary;
      }
    }

    .rd-navbar-search-toggle span {
      color: $brand-primary;
    }

    &.rd-navbar--is-stuck {
      .rd-navbar-search-form .form-label {
        color: $gray-dark;
      }
      .rd-navbar-search-form-input {
        color: $gray-darker !important;
      }
    }
  }

  // Light, Dark Theme
  // -------------------------

  &.rd-navbar-light, &.rd-navbar-dark {
    &.rd-navbar-static {

      .rd-navbar-inner {
        padding: 36px 15px 36px 15px !important;

        @media (min-width: 1400px) {
          padding: $rd-navbar-default-inner-padding !important;
        }
      }

      &.rd-navbar--is-stuck {
        .rd-navbar-inner {
          padding-top: $rd-navbar-default-stuck-inner-padding-top;
          padding-bottom: $rd-navbar-default-stuck-inner-padding-bottom;
        }
      }

      .rd-navbar-megamenu {
        margin-top: 0;
      }
    }
  }

  // Mobile Fixed Layout Colors
  &.rd-navbar-fixed {
    @extend %rd-navbar-default-panel-light;
    @extend %rd-navbar-default-mobile-menu-light;
    @extend %rd-navbar-default-cart-aside-right-light;
    @extend %rd-navbar-default-aside-left-light;
    @extend %rd-navbar-default-mobile-search-light;
  }

  // Transparent, Dark Theme
  // -------------------------

  &.rd-navbar-transparent, &.rd-navbar-dark {
    @extend %rd-navbar-default-cart-dark;

    // Desktop Static Layout Colors
    &.rd-navbar-static {
      @extend %rd-navbar-default-menu-first-lvl-dark;
      @extend %rd-navbar-default-menu-dropdown-dark;
      @extend %rd-navbar-default-search-dark;

      .rd-navbar-search-form-input, .rd-navbar-search-toggle, .rd-navbar-cart {
        color: $rd-navbar-default-dark-inputs-color;
      }

      &.rd-navbar--is-stuck {
        background: $rd-navbar-default-dark-stuck-background;

      }
    }
  }

  // Dark Theme
  // -------------------------

  &.rd-navbar-dark {
    background: $rd-navbar-default-dark-stuck-background;
  }

  // Light Theme
  // -------------------------

  &.rd-navbar-light {
    background: $rd-navbar-default-light-background;
    @extend %rd-navbar-default-cart-light;

    .rd-navbar-search-toggle, .rd-navbar-cart {
      span {
        color: $rd-navbar-default-light-toggles-color;
      }
    }

    // Desktop Static Layout Colors
    &.rd-navbar-static {
      @extend %rd-navbar-default-menu-first-lvl-light;
      @extend %rd-navbar-default-menu-dropdown-light;
      @extend %rd-navbar-default-search-light;

      .rd-navbar-search-form-input, .rd-navbar-search-toggle, .rd-navbar-cart {
        color: $rd-navbar-default-light-inputs-color;
      }
      &.rd-navbar--is-stuck {
        background: $rd-navbar-default-light-stuck-background;
      }
    }
  }
}
//
// Panels custom
// --------------------------------------------------

// Collapsable panels (aka, accordion)
//
// Wrap a series of panels in `.panel-group` to turn them into an accordion with
// the help of our collapse JavaScript plugin.

.panel-title,
.panel-heading,
.panel-footer {
	font-size: $panel-size;
	font-weight: $panel-weight;
	text-transform: uppercase;
}

.panel-group {
	.panel-title {
		padding-right: 25px;
	}

	&.accordion {
		.panel-heading {
			background: $brand-java;
			position: relative;
			padding: 0;
			border: none;
			color: $white;

			a {
				display: inline-block;
				width: 100%;
				padding: 13px 22px 13px 58px;
				text-transform: none;
				font-size: 15px;
				font-weight: 700;
				transition: .2s;

				&:after {
					position: absolute;
					top: 5px;
					bottom: 0;
					left: 14px;
					width: 20px;
					font-family: "Material Design Icons";
					font-size: 26px;
					font-weight: 400;
					color: $white;
					content: '\f465';
				}

				&.collapsed {
					&:after {
						content: '\f504';
					}
				}
			}
		}

		.panel-body {
			padding: 8px 25px 15px 20px;
			font-size: 16px;
			line-height: (25/16);
			border: none;
			background: $brand-java;
			color: $gray-light;
			border: none !important;
		}

		.panel + .panel {
			margin-top: 10px;
		}
	}

	@media (min-width: $screen-md-min) {
		&.accordion .panel-heading {
		}
	}
}
// Text emphasis
// -------------------------

@mixin text-emphasis-variant($parent, $color, $hcolor: null) {
	#{$parent} {
		&, a, a:focus {
			color: $color;
		}

		a:hover {
			@if ($hcolor) {
				color: $hcolor;
			} @else {
				color: darken($color, 10%);
			}
		}
	}

	a#{$parent}:focus {
		color: $color;
	}

	a#{$parent}:hover {
		@if ($hcolor) {
			color: $hcolor;
		} @else {
			color: darken($color, 10%);
		}
	}
}

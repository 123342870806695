//
// RD Navbar Default Aside Left
// --------------------------------------------------

%rd-navbar-default-mobile-menu {
  .rd-navbar-nav {
    padding: 0;
    margin-top: $rd-navbar-mobile-menu-list-offset-top;
    > li:last-child {
      margin-bottom: 10px;
    }
    li {
      float: none;
      position: relative;

      + li {
        margin-top: $rd-navbar-mobile-menu-list-item-offset-top;
      }
      a {
        @extend %rd-navbar-default-link-font;
        cursor: pointer;
        display: block;
        padding: $rd-navbar-mobile-menu-list-item-link-padding;
        span {
          vertical-align: middle;
        }
      }
      .rd-navbar-label {
        margin-left: $rd-navbar-mobile-menu-list-item-label-offset-left;
      }
      .rd-navbar-icon {
        margin-right: $rd-navbar-mobile-menu-list-item-icon-offset-right;
        vertical-align: middle;
        font-size: $rd-navbar-mobile-menu-list-item-icon-size;
      }
      &.opened {
        > .rd-navbar-submenu-toggle:before {
          content: $rd-navbar-mobile-menu-list-item-arrow-active-content;
        }
      }

      .resp-tab-active {
        > .resp-arrow:before {
          content: $rd-navbar-mobile-menu-list-item-arrow-active-content;
        }
      }
    }
  }

  .rd-navbar-submenu {
    position: relative;
    text-align: left;

    .col-md-3 {
      width: 100% !important;
    }

    ul li a {
      font-size: 14px;
    }
  }

  h6 {
    font-size: 16px;
    padding-left: 24px;
    margin-top: 22px;
  }

  hr {
    margin-bottom: 6px;
  }

  .text-subline {
    width: 50%;
    left: 0;
    display: inline-block;
    margin-left: 25px;
    margin-bottom: 10px;
  }

  .rd-navbar-submenu-toggle, .responsive-tabs .resp-accordion .resp-arrow {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    width: $rd-navbar-mobile-menu-list-item-arrow-width;
    font-size: $rd-navbar-mobile-menu-list-item-arrow-size;
    line-height: $rd-navbar-mobile-menu-list-item-arrow-line-height;
    font-family: $rd-navbar-mobile-menu-list-item-arrow-family;
    transition: .3s all ease;
    &:before {
      content: $rd-navbar-mobile-menu-list-item-arrow-content;
      display: block;
      vertical-align: middle;
    }
  }

  .rd-navbar-dropdown,
  .rd-navbar-megamenu {
    display: none;
    padding: $rd-navbar-mobile-menu-dropdown-padding;

    > li {
      > a {
        padding-left: $rd-navbar-mobile-menu-dropdown-item-link-padding-left;
      }
      .rd-navbar-dropdown > li {
        > a {
          padding-left: $rd-navbar-mobile-menu-dropdown2-item-link-padding-left;
        }
        .rd-navbar-dropdown > li > a {
          padding-left: $rd-navbar-mobile-menu-dropdown3-item-link-padding-left;
        }
      }
    }
  }

  .rd-navbar-megamenu {
    .responsive-tabs .resp-accordion {
      padding-left: $rd-navbar-mobile-menu-megamenu-tabs-padding-left;
    }
    .resp-tab-content ul > li > a {
      padding-left: $rd-navbar-mobile-menu-megamenu-tabs-content-list-item-link-padding-left;
    }
    .resp-tab-content {
      padding: $rd-navbar-mobile-menu-megamenu-tabs-content-padding;
    }
  }

  .rd-navbar-submenu.opened {
    > .rd-navbar-dropdown, > .rd-navbar-megamenu {
      display: block;
    }
  }

  .responsive-tabs {
    .resp-tabs-list {
      display: none;
    }
    ul {
      padding: 0;
    }
    .resp-accordion {
      padding: $rd-navbar-mobile-menu-tabs-accordion-padding;
      line-height: $rd-navbar-mobile-menu-tabs-accordion-line-height;
      margin-bottom: $rd-navbar-mobile-menu-tabs-accordion-offset-bottom;
    }
  }

  .responsive-tabs .resp-accordion {
    cursor: pointer;
    display: block;
    @extend %rd-navbar-default-link-font;
    span {
      margin-right: 5px;
      vertical-align: middle;
      font-size: 20px;
    }
  }
}

//
// Dark Theme
// --------------------------------------------------

%rd-navbar-default-mobile-menu-dark {

  .rd-navbar-nav {
    li {
      a, .responsive-tabs .resp-accordion {
        color: $rd-navbar-dark-mobile-menu-list-item-link-color;
      }

      &:hover {
        > a {
          background: $rd-navbar-dark-mobile-menu-list-item-hover-link-background;
        }
        > .rd-navbar-submenu-toggle {
          color: $rd-navbar-dark-mobile-menu-list-item-hover-toggle-color;
        }
      }

      .responsive-tabs .resp-accordion {
        .resp-arrow {
          color: $rd-navbar-dark-mobile-menu-list-item-arrow-color;
        }

        &.resp-tab-active, &:hover {
          background: $rd-navbar-dark-mobile-menu-list-item-tabs-active-background !important;
          .resp-arrow {
            color: $rd-navbar-dark-mobile-menu-list-item-tabs-active-arrow-color;
          }
        }
      }

      &.opened, &.active {
        > a {
          background: $rd-navbar-dark-mobile-menu-list-item-hover-link-background;
        }
        > .rd-navbar-submenu-toggle {
          color: $rd-navbar-dark-mobile-menu-list-item-hover-toggle-color;
        }
      }
    }
  }

  .rd-navbar-dropdown, .rd-navbar-megamenu {
    > li > a {
      color: $rd-navbar-dark-mobile-menu-dropdown-item-link-color;
    }
  }

  .rd-navbar-submenu-toggle {
    color: $rd-navbar-dark-mobile-menu-submenu-toggle-color;
  }
}

//
// Light Theme
// --------------------------------------------------

%rd-navbar-default-mobile-menu-light {
  .rd-navbar-nav {
    li {
      a {
        padding: 6px 30px 6px 15px;
      }
      a, .responsive-tabs .resp-accordion {
        color: $rd-navbar-light-mobile-menu-list-item-link-color;
      }

      .rd-navbar-submenu-toggle {
        color: $brand-primary;
      }

      &:hover {
        > a {
          color: $rd-navbar-light-mobile-menu-list-item-hover-link-color;
          background: $rd-navbar-light-mobile-menu-list-item-hover-link-background;
        }
        > .rd-navbar-submenu-toggle {
          color: $rd-navbar-light-mobile-menu-list-item-hover-toggle-color;
        }
      }

      .responsive-tabs .resp-accordion {
        .resp-arrow {
          color: $rd-navbar-light-mobile-menu-list-item-arrow-color;
        }

        &.resp-tab-active,
        &:hover {
          background: $rd-navbar-light-mobile-menu-list-item-tabs-active-background !important;
          color: $rd-navbar-light-mobile-menu-list-item-tabs-active-color;
          .resp-arrow {
            color: $rd-navbar-light-mobile-menu-list-item-tabs-active-arrow-color;
          }
        }
      }

      &.opened,
      &.active {
        > a {
          color: $rd-navbar-light-mobile-menu-list-item-hover-link-color;
          background: $rd-navbar-light-mobile-menu-list-item-hover-link-background;
        }
        > .rd-navbar-submenu-toggle {
          color: $rd-navbar-light-mobile-menu-list-item-hover-toggle-color;
        }
      }
    }
  }

  .rd-navbar-dropdown,
  .rd-navbar-megamenu {
    hr,
    h6 {
      display: none;
    }
    li > a {
      color: $rd-navbar-light-mobile-menu-dropdown-item-link-color;
      text-transform: capitalize !important;
      font-size: 14px;
      font-family: $font-family-base;
    }
    li {
      margin-left: 10px;
      .rd-navbar-submenu-toggle {
        display: none;
      }
      &:after {
        cursor: pointer;
        position: absolute;
        top: 50%;
        left: 15px;
        font-size: 14px;
        font-family: "FontAwesome";
        line-height: 14px;
        content: "";
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        transition: .3s all ease;
        opacity: 0;
        visibility: hidden;
        color: darken(saturate(adjust_hue($brand-primary, -0.3138528138528329), 0.12536162005786622%), 14.509803921568626%);
      }

      &:hover {
        &:after {
          opacity: 1;
          visibility: visible;
        }

        a {
          padding-left: 35px;
        }
      }
    }
    li > a > .rd-navbar-icon {
      color: $rd-navbar-light-mobile-menu-dropdown-item-link-icon-color;
    }
  }

  .rd-navbar-submenu-toggle {
    color: $rd-navbar-light-mobile-menu-submenu-toggle-color;
  }
}
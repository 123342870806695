@mixin responsive-offset($offset) {
  position: relative;
  transform: translateY(-$offset);
  margin-bottom: -$offset;

  > * {
    margin-top: $offset;
    &:before {
      content: '';
      display: table;
    }
  }
}

@mixin responsive-offset-media($offset) {
  transform: translateY(-$offset);
  margin-bottom: -$offset;

  > * {
    margin-top: $offset;
  }
}
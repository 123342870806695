//
// Custom Pagination
// --------------------------------------------------


// Classic Pagination
// -------------------------

.pagination-classic {
  @include list-unstyled();
  margin-left: -5px;
  font-size: 0;

  > li { 
    display: inline-block;
    margin-left: 9px;

    > a,
    > span{
      border-radius: 50%;
      padding: 9px 0;
      width: 50px;
      height: 50px;
      font-size: 15px;
      min-width: $btn-lh-size-small + $padding-small-vertical*2 + $border-width-small*2;
    }

    &:first-child{
      > a,
      > span {
        padding-left: 0;
      }
    }

    &:last-child{
      > a,
      > span {
        padding-right: 0;
      }
    }


      &.active {
        > a,
        > span {
          color: $pagination-active-color;
          background: $pagination-active-bg;
          border-color: $pagination-active-border;
          cursor: default;
        }
      }
  }
}

// Bootstrap Pagination
// -------------------------

.pagination {
  @include pagination-size($padding-small-vertical, $padding-small-horizontal/2, $btn-font-size-small, $btn-lh-size-small, $btn-border-radius-small);

  > li > a,
  > li > span {
    min-width: $padding-small-vertical*2 + $border-width-small*2 + $btn-lh-size-small;
    font-weight: $btn-font-weight;
    transition: .3s;
    text-align: center;
  }

  > li:first-child,
  > li:last-child {
    a {
      > .mdi {
        position: relative;
        font-size: 16px;
        line-height: 1;
      }

      > .mdi-chevron-double-right,
      > .mdi-chevron-double-left {
        top: 2px;
      }
    }
  }
}

.pagination-sm {
  @include pagination-size($padding-xs-vertical, $padding-xs-horizontal/2, $btn-font-size-xs, $btn-lh-size-xs, $btn-border-radius-small);

  > li {
    > a,
    > span {
      min-width: $padding-xs-vertical*2 + $border-width-small*2 + $btn-lh-size-xs;
    }
  }
}

.pagination-lg {
  @include pagination-size($padding-base-vertical, $padding-base-horizontal/2, $btn-font-size, $btn-lh-size, $btn-border-radius-small);

  > li {
    > a,
    > span {
      min-width: $padding-base-vertical*2 + $border-width-small*2 + $btn-lh-size;
    }
  }

  > li:first-child,
  > li:last-child {
    &,
    > a{
      > .mdi {
        font-size: 20px;
      }
    }
  }
}
//
// RD Navbar Sidebar Fixed Menu
// --------------------------------------------------

%rd-navbar-sidebar-fixed-menu {
  .rd-navbar-nav-wrap {
    @include rd-navbar-sidebar(280px, "left");
    padding: $rd-navbar-fixed-height $rd-navbar-aside-left-padding;
    &.active {
      @include transform(translateX(0));
    }
  }
  .rd-navbar-nav {
    text-align: left;
    li {
      @extend %rd-navbar-transition;
    }
  }

  @media (min-width: $screen-lg) {
    .rd-navbar-nav-wrap {
      position: fixed;
      left: 0;
      top: 0;
      @include display-flex;
      @include flex-direction(column);
      @include justify-content(center);
      @include align-items(center);
      width: 100%;
      height: 100vh;
      padding: 0;
      opacity: 0;
      visibility: hidden;
      @include transition(1.3s all ease);
      @include transform(translateX(0));
      &.active {
        opacity: 1;
        visibility: visible;
        @include transition(0.35s all ease);
      }
    }
    .rd-navbar-title {
      opacity: 0;
      @include transform(translateY(30px) scale(.8));
      @include transition(0.35s all ease);
    }
    .rd-navbar-nav {
      text-align: center;
      li {
        @include transform(translateY(25px));
        opacity: 0;
        a {
          padding: 0;
          font-size: $rd-navbar-sidebar-fixed-list-item-link-size;
          line-height: $rd-navbar-sidebar-fixed-list-item-link-size;
          font-weight: $rd-navbar-sidebar-fixed-list-item-link-weight;
          text-transform: capitalize;
        }
        & + li {
          margin-top: $rd-navbar-sidebar-fixed-list-item-offset-top;
        }
      }
    }
    .rd-navbar-nav-wrap.active {
      .rd-navbar-title {
        transition-delay: 0.1s;
        @include transform(translateY(0) scale(1));
        opacity: 1;
      }
      .rd-navbar-nav {
        @for $i from 1 to 20 {
          li:nth-child(#{$i}) {
            transition-delay: 0.2s +(( $i / 7 ) * 1s);
          }
        }
        li {
          @include transform(translateY(0));
          opacity: 1;
        }
      }
    }
  }
}

//
// Dark Theme
// --------------------------------------------------

%rd-navbar-sidebar-fixed-menu-dark {
  .rd-navbar-nav-wrap {
    color: $rd-navbar-sidebar-fixed-light-wrap-color;
    background: $rd-navbar-sidebar-fixed-light-wrap-background;
  }
  .rd-navbar-nav {
    li {
      &.active, &:hover {
        a {
          background: $rd-navbar-sidebar-fixed-light-list-item-active-background;
          color: $rd-navbar-sidebar-fixed-light-list-item-active-color;
        }
      }
    }
  }
  @media (min-width: $screen-lg) {
    .rd-navbar-nav-wrap {
      background: rgba($rd-navbar-sidebar-fixed-light-wrap-background, .9);
    }
  }
}

//
// Light Theme
// --------------------------------------------------

%rd-navbar-sidebar-fixed-menu-light {
  .rd-navbar-nav-wrap {
    color: $rd-navbar-sidebar-fixed-dark-wrap-color;
    background: $rd-navbar-sidebar-fixed-dark-wrap-background;
  }
  .rd-navbar-nav {
    li {
      &.active, &:hover {
        a {
          background: $rd-navbar-sidebar-fixed-dark-list-item-active-background;
          color: $rd-navbar-sidebar-fixed-dark-list-item-active-color;
        }
      }
    }
  }
  @media (min-width: $screen-lg) {
    .rd-navbar-nav-wrap {
      background: rgba($rd-navbar-sidebar-fixed-dark-wrap-background, .95);
    }
  }
}
//
// RD Navbar Default Menu Dropdown
// --------------------------------------------------

%rd-navbar-default-menu-dropdown {
  .rd-navbar-dropdown,
  .rd-navbar-megamenu,
  .rd-navbar-cart-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    width: 240px;
    padding: 0;
    visibility: hidden;
    transition: .3s all ease;
    text-align: left;
    opacity: 0;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.20);

    li {
      padding: 4px 0 4px 0px;
      margin-left: 25px;
      margin-right: 25px;
      border-bottom: 1px solid $gray-light;

      &:last-child {
        border: none;
      }
    }

    li a {
      text-transform: capitalize;
      font-size: 14px;
      font-family: $font-family-base;
      color: $brand-primary;
      white-space: normal;

      &:hover {
        color: darken(saturate(adjust_hue($brand-primary, -0.3138528138528329), 0.12536162005786622%), 14.509803921568626%);
      }
    }

    .rd-navbar-dropdown {
      left: 100%;
      top: -1px;
      margin: 0;
      z-index: 2;
    }

    .rd-navbar-dropdown .rd-navbar-dropdown {
      z-index: 3;
    }

    &.rd-navbar-open-left,
    &.rd-navbar-open-left .rd-navbar-dropdown {
      right: 102%;
      left: auto;
    }
  }

  .rd-navbar-nav > .rd-navbar-submenu > .rd-navbar-dropdown.rd-navbar-open-left {
    right: 0;
  }



  .rd-navbar-dropdown{
    padding-bottom: 5px;
  }

  .rd-navbar-dropdown,
  .rd-navbar-cart-dropdown {
    margin-top: 0px;
  }

  .rd-navbar-dropdown, .rd-navbar-megamenu {
    li {
      position: relative;
      > a {
        @extend %rd-navbar-default-link-font;
        display: block;
        padding: $rd-navbar-menu-dropdown-lists-dropdown-megamenu-item-link-padding;
        > span {
          vertical-align: middle;
        }
      }
      .rd-navbar-label {
        margin-left: 5px;
      }
      .rd-navbar-icon {
        margin-right: 10px;
        font-size: 18px;
        line-height: 24px;
        vertical-align: middle;
      }

      &:after {
        opacity: 0;
        visibility: hidden;
        color: $brand-primary;
      }

      &:hover,
      &.active {
        &:after {
          opacity: 1;
          visibility: visible;
        }
        > a {
          padding-left: 20px;
        }
      }
    }

    li {
      &:after {
        cursor: pointer;
        position: absolute;
        top: 50%;
        left: 0px;
        font-size: $rd-navbar-menu-dropdown-lists-dropdown-megamenu-item-caret-icon-size;
        font-family: $rd-navbar-menu-dropdown-lists-dropdown-megamenu-item-caret-icon-family;
        line-height: $rd-navbar-menu-dropdown-lists-dropdown-megamenu-item-caret-icon-line-height;
        content: $rd-navbar-menu-dropdown-lists-dropdown-megamenu-item-caret-icon;
        @include transform(translateY(-50%));
        transition: .3s all ease;
      }
    }
  }

  .rd-navbar-megamenu {
    left: 50%;
    padding: 30px;
    max-width: 1170px;
    margin-left: auto;
    width: 100%;
    @include transform ( translateX(-50%) );

    li{
      padding: 0px 0 0px 0px;
      margin-left: 0px;
      margin-right: 0px;
      border:none;
      > a {
        padding-right: 0;
      }
    }

    .text-subline {
      margin-bottom: 12px;
    }
  }

  // Megamenu Tabs
  .responsive-tabs .resp-tabs-list {
    text-align: left;
    padding-left: 20px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: -15px;
      right: -15px;
      top: 100%;
      border-bottom: 1px solid transparent;
    }
    li {
      &:before {
        display: none;
      }
      &.resp-tab-active {

        &:hover {
          color: $rd-navbar-menu-dropdown-megamenu-active-item-hover-color;
        }
      }
    }
  }
}

//
// Dark Theme
// --------------------------------------------------

%rd-navbar-default-menu-dropdown-dark {
  .rd-navbar-dropdown, .rd-navbar-megamenu {
    background: $rd-navbar-dark-menu-dropdown-lists-dropdown-megamenu-background;

    li {
      &.rd-navbar--has-dropdown:after {
        color: $rd-navbar-dark-menu-dropdown-lists-dropdown-megamenu-active-item-color;
      }
      > a {
        color: $rd-navbar-dark-menu-dropdown-lists-dropdown-megamenu-item-link-color;
      }
      .rd-navbar-icon {
        color: $rd-navbar-dark-menu-dropdown-lists-dropdown-megamenu-item-icon-color;
      }
      &:hover {
        > a {
          color: $rd-navbar-dark-menu-dropdown-lists-dropdown-megamenu-item-hover-link-color;
          background: $rd-navbar-dark-menu-dropdown-lists-dropdown-megamenu-item-hover-link-background;
        }
        &:after {
          color: $rd-navbar-dark-menu-dropdown-lists-dropdown-megamenu-item-hover-link-after-color;
        }
      }
    }

    .responsive-tabs .resp-tabs-list {
      &:before {
        border-color: $rd-navbar-dark-menu-dropdown-lists-dropdown-megamenu-before-border-color;
      }
      li {
        color: $rd-navbar-dark-menu-dropdown-lists-dropdown-megamenu-item-color;

        &.resp-tab-active {
          &, &:before {
            color: $rd-navbar-dark-menu-dropdown-lists-dropdown-megamenu-item-active-color;
          }
          &:hover {
            color: $rd-navbar-dark-menu-dropdown-lists-dropdown-megamenu-item-active-hover-color;
          }
        }
        &:hover {
          color: $rd-navbar-dark-menu-dropdown-lists-dropdown-megamenu-item-hover-color;
        }
      }
    }
  }
}

//
// Light Theme
// --------------------------------------------------

%rd-navbar-default-menu-dropdown-light {
  .rd-navbar-dropdown, .rd-navbar-megamenu {
    background: $rd-navbar-light-menu-dropdown-lists-dropdown-megamenu-background;
    li {
      &.rd-navbar--has-dropdown:after {
        color: $rd-navbar-light-menu-dropdown-lists-dropdown-megamenu-active-item-color;
      }
      > a {
        color: $rd-navbar-light-menu-dropdown-lists-dropdown-megamenu-item-link-color;
      }
      .rd-navbar-icon {
        color: $rd-navbar-light-menu-dropdown-lists-dropdown-megamenu-item-icon-color;
      }
      &:hover {
        > a {
          color: $rd-navbar-light-menu-dropdown-lists-dropdown-megamenu-item-hover-link-color;
          background: $rd-navbar-light-menu-dropdown-lists-dropdown-megamenu-item-hover-link-background;
        }
        &:after {
          color: $rd-navbar-light-menu-dropdown-lists-dropdown-megamenu-item-hover-link-after-color;
        }
      }
    }

    .responsive-tabs .resp-tabs-list {
      &:before {
        border-color: $rd-navbar-light-menu-dropdown-lists-dropdown-megamenu-before-border-color;
      }
      li {
        color: $rd-navbar-light-menu-dropdown-lists-dropdown-megamenu-item-color;

        &.resp-tab-active {
          &, &:before {
            color: $rd-navbar-light-menu-dropdown-lists-dropdown-megamenu-item-active-color;
          }
          &:hover {
            color: $rd-navbar-light-menu-dropdown-lists-dropdown-megamenu-item-active-hover-color;
          }
        }
        &:hover {
          color: $rd-navbar-light-menu-dropdown-lists-dropdown-megamenu-item-hover-color;
        }
      }
    }
  }
}
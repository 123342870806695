/*
* @subsection Google Map
* @description Describes style declarations for RD Google Map extension
* @version     1.0.0
*/
$map-height:    200px;
$map-xs-height: 250px;
$map-sm-height: 450px;

.google-map-markers{
  display: none;
}

.google-map-container {
  width: 100%;
}
.google-map {
  height: $map-height;
  width: 100%;

  @media (min-width: $screen-xs-min) {
    height: $map-xs-height;
  }

  @media (min-width: $screen-sm-min) {
    height: $map-sm-height;
  }
}

//
// RD Navbar Minimal
// --------------------------------------------------

.rd-navbar-minimal {
  @extend %rd-navbar-transition;

  &.rd-navbar-fixed {
    @extend %rd-navbar-default-panel;
    @extend %rd-navbar-default-mobile-menu;
    @extend %rd-navbar-default-cart-aside-right;
    @extend %rd-navbar-default-aside-left;
    @extend %rd-navbar-default-mobile-search;

    .contact-info {
      margin-top: 10px!important;
    }

    .rd-navbar-toggle {
      color: $gray-darkest;
      span:before,
      span:after,
      span {
        background: $gray-darkest;
      }
    }

    .rd-navbar-top-panel-toggle {
      position: fixed;
      z-index: 17;
      top: 4px;
      right: 4px;
      padding: 0;
      background: none;
      border: none;
      outline: none;
      @include toggle-icons-via-rotation(48px, 28px, $rd-navbar-light-top-panel-toggle-icon-color, $rd-navbar-top-panel-toggle-icon-state-before, $rd-navbar-top-panel-toggle-icon-state-after);
      &:focus, &:active {
        outline: none;
      }
    }

    .rd-navbar-brand {
      max-width: 200px;
    }

    .contact-info + .contact-info {
      margin-top: 23px;
    }

    .rd-navbar-top-panel {
      position: fixed;
      right: 4px;
      top: 50px;
      background: $white;
      width: $rd-navbar-top-panel-mobile-width;
      padding: $rd-navbar-top-panel-mobile-padding;
      z-index: 17;
      overflow: hidden;
      transition: box-shadow .3s ease;
      opacity: 0;
      visibility: hidden;
      > * {
        opacity: 0;
        visibility: hidden;
      }
      &:before {
        content: '';
        position: absolute;
        top: -22%;
        right: -22%;
        width: 0;
        height: 0;
        transition: all .3s ease;
        border-radius: 50%;
        z-index: -1;
      }
      &.active {
        opacity: 1;
        visibility: visible;
        box-shadow: $shadow-area-md;
        > * {
          opacity: 1;
          visibility: visible;
          transition: opacity .3s ease .2s;
        }

        &:before {
          height: 150%;
          width: 150%;
        }
      }
    }

    .rd-navbar-top-links {
      margin-bottom: $rd-navbar-top-panel-mobile-margin-bottom;
    }

    .contact-info {
      > span {
        display: block;
      }
      span + span {
        margin: 0;
      }
    }

    .rd-navbar-brand {
      display: none;
    }

    .rd-navbar-search {
      display: none;
    }

    @media (min-width: $screen-xs) {
      .rd-navbar-mobile-brand {
        display: none;
      }
      .rd-navbar-brand {
        display: block;
      }
    }
  }

  &.rd-navbar-static {
    .rd-navbar-mobile-brand {
      display: none;
    }

    .rd-navbar-top-panel-toggle {
      display: none;
    }

    @extend %rd-navbar-default-menu-first-lvl;
    @extend %rd-navbar-default-menu-dropdown;
    @extend %rd-navbar-default-search;

    .rd-navbar-search-form .form-label {
      color: $white;

      &.focus {
        top: 0px;
        font-size: 15px;
        color: $gray-lightest;
      }
    }

    .rd-navbar-search-form .rd-navbar-search-form-input {
      padding: 0px 8% 0px 50px;
      color: $white;
    }

    .rd-navbar-top-panel {
      @include display-flex();
      @include justify-content(space-between);

      .contact-info + .contact-info {
        margin-left: 130px;
      }
    }

    .rd-navbar-megamenu,
    .rd-navbar-dropdown {
      background: $white;
    }

    .rd-navbar-top-panel,
    .rd-navbar-menu-wrap,
    .rd-navbar-panel {
      max-width: 1170px;
      margin-left: auto;
      margin-right: auto;
    }

    .rd-navbar-menu-wrap {
      height: 60px;
      @include display-flex();
      @include justify-content(center);
      @include align-items(center);
      background: $brand-primary;
      box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      max-width: 1170px;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      top: 32px;

      &.active {
        .rd-navbar-nav-wrap {
          opacity: 0;
          visibility: hidden;
        }
      }

      .rd-navbar-nav-wrap {
        margin-top: 0px;
      }

      .rd-navbar-nav > li > a {
        color: $white;
      }
    }

    .rd-navbar-brand {
      img {
        vertical-align: baseline;
      }
    }

    .rd-navbar-brand-slogan {
      vertical-align: baseline;
      font-size: $rd-navbar-default-slogan-size;
      margin-left: $rd-navbar-default-slogan-offset-left;
    }

    .rd-navbar-panel .rd-navbar-toggle,
    .form-search-wrap {
      display: none;
    }

    .rd-navbar-inner {
      @include clearfix;
      transition: .3s all ease;
      position: relative;
      text-align: left;
      padding: 38px 15px 0;

      &.active {
        .rd-navbar-panel {
          opacity: 0;
          visibility: hidden;
        }
      }
    }

    .rd-navbar-megamenu {
      margin-top: $rd-navbar-default-megamenu-offset-top;
    }

    .rd-navbar-nav-wrap, .rd-navbar-panel, .rd-navbar-cart-wrap {
      transition: .3s all ease;
    }

    .rd-navbar-nav-wrap {
      @include clearfix;
      margin-top: 14px;
      display: inline-block;
    }

    .rd-navbar-search-toggle, .rd-navbar-cart {
      margin-top: 5px;
    }

    &.rd-navbar--is-stuck {
      background: $white;

      .rd-navbar-search-form .form-label {
       &.focus {
          top: 0px;
        }
      }
      .rd-navbar-menu-wrap {
        max-width: none;
        margin-top: 0;
        border-radius: 0;
      }

      .rd-navbar-menu-wrap {
        position: static;
      }

      .rd-navbar-inner {
        padding: 0;
      }

      .rd-navbar-top-panel,
      .rd-navbar-panel {
        display: none;
      }
    }

    &.rd-navbar--is-stuck {
      .rd-navbar-search-form .rd-navbar-search-form-input {
        padding: 0px 8% 0px 20px;
      }
    }
  }

  // Themes
  // --------------------------------------------------

  // Transparent, Dark Theme
  // -------------------------

  &.rd-navbar-transparent, &.rd-navbar-dark {
    @extend %rd-navbar-default-cart-dark;

    // Mobile Fixed Layout Colors
    &.rd-navbar-fixed {
      @extend %rd-navbar-default-panel-dark;
      @extend %rd-navbar-default-mobile-menu-dark;
      @extend %rd-navbar-default-cart-aside-right-dark;
      @extend %rd-navbar-default-aside-left-dark;
      @extend %rd-navbar-default-mobile-search-dark;
    }

  }

  // Light Theme
  // -------------------------

  &.rd-navbar-light {
    background: $rd-navbar-default-light-background;
    @extend %rd-navbar-default-cart-light;

    // Mobile Fixed Layout Colors
    &.rd-navbar-fixed {
      @extend %rd-navbar-default-panel-light;
      @extend %rd-navbar-default-mobile-menu-light;
      @extend %rd-navbar-default-cart-aside-right-light;
      @extend %rd-navbar-default-aside-left-light;
      @extend %rd-navbar-default-mobile-search-light;
    }

  }
}



//
// Filled Icons
// --------------------------------------------------

@mixin filled-icon($parent, $background-color, $text-color) {
  #{$parent} {
    color: $text-color;
    @extend #{$background-color};
    @content;

    &.icon-outlined {
      &:after {
        @extend #{$background-color};
      }
    }
  }
}
//
// Custom Tables
// --------------------------------------------------

// Base styles
// --------------------------------------------------

// Bootstrap
// -------------------------

.table {
  text-align: left;
  tr:first-child {
    td {
      font-weight: 700;
    }
  }

  th tbody, td tbody {
    padding: 7px 18px;
  }

  @media (min-width: $screen-md-min) {
    th, td {
      &:nth-child(n+2) {
        padding-left: 56px;
      }
    }
  }
}

// Table Custom
// -------------------------

.table-custom {
  font-size: 14px;
  text-align: left;
  th {
    letter-spacing: 0;
    color: $gray-darker;
    font-weight: 400;
  }
  td {
    color: $gray-dark;
  }
  tbody {
    tr {
      border: 1px solid #e5e5e5;
      border-left: none;
      border-right: none;
      &:first-child {
        border: 1px solid #f9f9f9;
      }
      td, th {
        border: none;
        vertical-align: middle;
        padding: 4px 19px;
        //padding: 13px 19px;

        &:first-child {
          width: 81px;
        }
      }
      td {
        padding: 4px 16px;
      }
    }
  }

  &.table-fixed {
    display: none;
    table-layout: fixed;
    th tbody, td tbody {
      padding: 0;
    }
    @media (min-width: $screen-md-min) {
      th, td {
        &:nth-child(n+2) {
          padding-left: 0;
        }
        padding: 13px 19px;
      }
    }
  }
  &.table-hover-rows,
  &.striped-table,
  &.condensed-table,
  &.bordered-table {
    tbody {
      tr {
        &:hover {
          background: #fafafa;
          cursor: pointer;
        }
        &:first-child {
          border: none;
          border-bottom: 1px solid $gray-base;
        }
      }
    }
  }
  &.striped-table,
  &.condensed-table,
  &.bordered-table {
    tbody {
      tr {
        &:hover {
          background: none;
          cursor: auto;
        }
        &:nth-child(even) {
          background: #fafafa;
        }
      }
    }
  }
  &.condensed-table {
    @media (min-width: $screen-md-min) {
      th, td {
        padding: 9px 23px;
      }
    }
  }
  &.bordered-table {
    tbody {
      tr {
        border: 1px solid #e5e5e5;
        td, th {
          border: 1px solid #e5e5e5;
        }
        th {
          border-bottom: none;
        }
        &:nth-child(n+2) {
          td {
            border-top: none;
          }
        }
        &:nth-child(even) {
          background: none;
        }

        th, td {
          &:first-child {
            width: 68px;
          }
        }
      }

      @media (min-width: $screen-md-min) {
        th, td {
          &:nth-child(n+2) {
            padding-left: 16px;
          }
          padding: 14px 19px;
        }
      }
    }
  }
  &.table-primary {
    tbody {
      tr {
        &:nth-child(even) {
          background: #fafafa;
        }
        &:first-child {
          th {
            font-weight: 700;
          }
        }
        th {
          background: $brand-primary;
          color: $white;
          border-top-color: $brand-primary;
        }
        &:first-child {
          th:first-child {
            border-top-left-radius: $border-radius-base + 1;
          }
          th:last-child {
            border-top-right-radius: $border-radius-base + 1;
          }
        }

        @media (max-width: $screen-sm-min - 1) {
          &:first-child {
            th {
              color: $gray-darker;
              background: transparent;
            }
          }
        }
      }
    }
  }
  &.table-dark-blue {
    tbody {
      tr {
        &:hover {
          background: $gray-lighter;
          cursor: pointer;
          .stepper {
            .form-control {
              background-color: #fafafa;
            }
          }
        }
        th {
          background: $brand-primary;
          color: $white;
          border-top-color: $brand-primary;
        }
        &:first-child {
          border: 1px solid $brand-primary;
        }
      }
    }
  }
  &.table-product {
    tbody tr:first-child {
      border-bottom: 1px solid $gray-light;
      td {
        font-weight: 400;
      }
    }
  }
}

@media (min-width: $screen-sm-min) {
  .table-3-col {
    th:nth-child(2) {
      width: 67%;
    }
  }
}

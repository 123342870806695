//
// Swiper
// --------------------------------------------------

.swiper-container {
	//height: 91vh;
	margin: 0 auto;
	position: relative;
	overflow: hidden;
	/* Fix of Webkit flickering */
	z-index: 1;

	h2 {
		letter-spacing: 0;
		@media (min-width: $screen-md-min) {
			font-size: 48px;
		}
		@media (min-width: 1400px) {
			font-size: 60px;
		}
	}

	h5 + * {
		margin-top: 1px;
	}

	.autror-name-pos {
		position: absolute;
		top: 98px;
		right: 0;
		display: none;

		@media (min-width: $screen-lg-min) {
			display: block;
			top: 165px;
		}
	}

	@media (min-width: $screen-md-min) {
		height: 560px;
	}

	.swiper-slide-caption,
	.shell,
	.range,
	.swiper-caption {
		height: 100%;
	}

}

.swiper-container-no-flexbox .swiper-slide {
	float: left;
}

.swiper-container-vertical > .swiper-wrapper {
	-webkit-box-orient: vertical;
	-moz-box-orient: vertical;
	-ms-flex-direction: column;
	-webkit-flex-direction: column;
	flex-direction: column;
}

.swiper-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 1;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-transition-property: -webkit-transform;
	-moz-transition-property: -moz-transform;
	-o-transition-property: -o-transform;
	-ms-transition-property: -ms-transform;
	transition-property: transform;
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
	-webkit-transform: translate3d(0px, 0, 0);
	-moz-transform: translate3d(0px, 0, 0);
	-o-transform: translate(0px, 0px);
	-ms-transform: translate3d(0px, 0, 0);
	transform: translate3d(0px, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
	-webkit-box-lines: multiple;
	-moz-box-lines: multiple;
	-ms-flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
	-webkit-transition-timing-function: ease-out;
	-moz-transition-timing-function: ease-out;
	-ms-transition-timing-function: ease-out;
	-o-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
	margin: 0 auto;
}

.swiper-slide {
	-webkit-flex-shrink: 0;
	-ms-flex: 0 0 auto;
	flex-shrink: 0;
	width: 100%;
	height: 100%;
	position: relative;

	.rd-video.rd-video-bg {
		height: 100%;
		z-index: auto;
	}

	.swiper-caption {
		position: relative;
		z-index: 9;
		min-height: 295px;

		@media (max-width: $screen-sm-min - 1px) {
			display: flex;
			align-items: center;
		}

		@media (min-width: $screen-sm-min) {
			min-height: 435px;
		}

		img {
			position: absolute;
			z-index: -1;
			bottom: 0;
			opacity: 0.2;
			max-width: 200px;
			left: 50%;
			@include transform (translateX(-50%));

			&.img-big {
				max-width: 300px;
			}

			@media (min-width: $screen-xs-min) {
				max-width: 210px;
				&.img-big {
					max-width: 390px;
				}
			}

			@media (min-width: $screen-sm-min) {
				max-width: 317px;
				&.img-first {
					max-width: 350px;
				}
				&.img-big {
					max-width: 600px;
				}
			}

			@media (min-width: $screen-md-min) {
				position: static;
				opacity: 1;
				max-width: none;
				@include transform (translateX(0));
				&.img-big,
				&.img-first {
					max-width: none;
				}
			}
		}

		.img-wrap {
			position: static;
			@media (min-width: $screen-md-min) {
				position: relative;
			}
		}
	}

}

.swiper-container .no-transition {
	transition: 0s;
}

/* a11y */
.swiper-container .swiper-notification {
	position: absolute;
	left: 0;
	top: 0;
	pointer-events: none;
	opacity: 0;
	z-index: -1000;
}

/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
	-ms-touch-action: pan-y;
	touch-action: pan-y;
}

.swiper-wp8-vertical {
	-ms-touch-action: pan-x;
	touch-action: pan-x;
}

/* Arrows */
.swiper-button-prev,
.swiper-button-next {
	display: none;
	position: absolute;
	top: 50%;
	width: $swiper-arrow-width;
	height: $swiper-arrow-height;
	line-height: $swiper-arrow-line-height;
	font-size: $swiper-arrow-size;
	text-align: center;
	margin-top: -($swiper-arrow-height/2);
	z-index: 10;
	cursor: pointer;
	opacity: 1;
	color: $swiper-arrow-color;

	&:hover {
		opacity: 1;
	}

	@media (min-width: $screen-md-min) {
		display: block;
	}
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
	opacity: 0.35;
	cursor: auto;
	pointer-events: none;
}

.swiper-button-prev {
	left: 23px;
}

.swiper-button-next {
	right: 3.5%;
}

/* Pagination Styles */

.swiper-pagination {
	display: block;
	position: absolute;
	text-align: center;
	transition: 300ms;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	z-index: 10;
}

// Pagination Type 1
//----------------------------
.desktop {
	.swiper-container {
		.swiper-pagination-type-1 {
			display: inline-block;
		}
	}
}

.swiper-pagination-type-1 {
	.swiper-pagination-bullet {
		position: relative;
		width: $swiper-dots-type-1-width;
		height: $swiper-dots-type-1-height;
		line-height: $swiper-dots-type-1-height;
		background: transparent;
		border: $swiper-dots-type-1-border;
		margin: $swiper-dots-type-1-offset;
		transition: .3s all ease;

		&-active, &:hover {
			@include transform(scale(1.4));
			background: $swiper-dots-type-1-active-background;
		}
	}
}

.swiper-pagination.swiper-pagination-hidden {
	opacity: 0;
}

.swiper-pagination-bullet {
	margin-left: $swiper-dots-offset-left;
	display: inline-block;
	width: $swiper-dots-width;
	height: $swiper-dots-height;
	line-height: $swiper-dots-height;
	border-radius: 50%;
	background: #969696;
	position: relative;
	transition: .2s ease-in-out;

	@media (min-width: $screen-md-min) {
		background: $swiper-dots-background;
		&:before {
			content: '';
			position: absolute;
			display: inline-block;
			top: -5px;
			left: -5px;
			width: 22px;
			height: 22px;
			background: rgba($brand-java, .22);
			transition: .2s ease-in-out;
			@include transform (scale(0));
			border-radius: 50%;
		}
		&-active, &:hover {
			background: $brand-java;
			&:before {
				@include transform (scale(1));
			}
		}
	}
}

.swiper-pagination-clickable .swiper-pagination-bullet {
	cursor: pointer;
}

.swiper-pagination-white .swiper-pagination-bullet {
	background: #FFF;
}

.swiper-pagination-bullet-active {
	background: $brand-java;
}

.swiper-pagination-white .swiper-pagination-bullet-active {
	background: #FFF;
}

.swiper-pagination-black .swiper-pagination-bullet-active {
	background: #000;
}

.swiper-container-vertical > .swiper-pagination {
	right: 10px;
	top: 50%;
	-webkit-transform: translate3d(0px, -50%, 0);
	-moz-transform: translate3d(0px, -50%, 0);
	-o-transform: translate(0px, -50%);
	-ms-transform: translate3d(0px, -50%, 0);
	transform: translate3d(0px, -50%, 0);
}

.swiper-container-vertical > .swiper-pagination .swiper-pagination-bullet {
	margin: 5px 0;
	display: block;
}

.swiper-container-horizontal > .swiper-pagination {
	bottom: 10px;
	//left: 50%;
	//transform: translateX(-50%);
	width: 100%;

	@media (min-width: $screen-md-min) {
		bottom: 59px;
	}

	// Swiper pagination type 1
	&.swiper-pagination-type-1 {
		bottom: 30px;
		width: 100%;
		@media (min-width: $screen-sm-min) {
			right: 30px;
			bottom: 65px;
			left: auto;
			width: auto;
		}
		.swiper-pagination-bullet {
			margin: 0 15px;
		}
	}
}

.swiper-container-horizontal > .swiper-pagination .swiper-pagination-bullet {
	margin: 0 13px;
}

.swiper-slide > .vide__body,
.swiper-slide > .parallax_cnt {
	height: 100%;
}

.swiper-slide {
	height: 100%;
	background-position: center;
	background: $white;

	.swiper-parallax {
		height: 100%;
		white-space: nowrap;
		&:before {
			content: '';
			display: inline-block;
			height: 50%;
		}
	}

	&-caption {
		display: inline-block;
		width: 100%;
		vertical-align: middle;
		white-space: normal;
		padding: 0;

		h4 {
			margin-bottom: 0;
		}
		@media (min-width: $screen-lg) {
			padding: 0 80px;
		}
	}

	@media (max-width: $screen-xs) {
		h1 {
			font-size: 32px;
		}

		h2 {
			font-size: 28px;
		}

		h3 {
			font-size: 24px;
		}

		h4 {
			font-size: 20px;
		}

		h5 {
			font-size: 18px;
		}

		.btn {
			font-size: 15px;
			&.btn-lg {
				padding: 10px 20px;
				&.btn-icon .icon {
					font-size: 28px;
				}
			}
		}
	}

	@media (min-width: 1920px) {
		h1 {
			font-size: 2.35vw;
		}
	}
}

.swiper-slide-overlay-disable {
	&:after {
		display: none;
	}

	@media (max-width: $screen-md-min - 1) {
		background-position: right center;
	}
}

.swiper-button {
	width: 60px;
	height: 100px;
	cursor: pointer;
	position: absolute;
	top: 50%;
	@include transform(translateY(-50%));
	z-index: 9;

	&:before {
		content: '';
		position: absolute;
		top: 50%;
		@include transform(translateY(-50%));
	}

	&:after {
		position: absolute;
		top: calc(50% - 1px);
		width: 30px;
		height: 2px;
		background: #FFF;
		content: '';
		-webkit-transition: width 0.3s 0.2s;
		transition: width 0.3s 0.2s;
		-webkit-backface-visibility: hidden; /* needed because of Chrome bug with prefixed calc */
		backface-visibility: hidden;
	}

	&.swiper-button-next {
		right: 23px;
		&:before {
			@include triangle(right, 13px, 16px, $white);
			right: 0;
		}
		&:after {
			right: 13px;
		}
		.preview {
			right: 40px;
			&:after {
				content: 'Next';
				text-align: right;
				@include transform(translateX(-125%));
			}
		}
	}

	&.swiper-button-prev {
		&:before {
			@include triangle(left, 13px, 16px, $white);
			left: 0;
		}
		&:after {
			left: 13px;
		}
		.preview {
			left: 40px;
			&:after {
				content: 'Previous';
				@include transform(translateX(125%));
			}
			&__img {
				float: left;
				&:first-child {
					width: 32px;
					height: 32px;
				}
				&:nth-child(2) {
					width: 48px;
					height: 48px;
				}
				&:nth-child(3) {
					width: 64px;
					height: 64px;
				}
			}
		}
	}

	.preview {
		position: absolute;
		top: -25%;
		@include display-flex();
		@include align-items(flex-end);
		width: 170px;
		pointer-events: none;
		&__img {
			margin: 0 5px;
			opacity: 0;
			box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5), 0px -1px 0 rgba(255, 255, 255, 0.5);
			transition: 0.3s;
			@include transform(scale(0.3));
			&:first-child {
				width: 64px;
				height: 64px;
			}
			&:nth-child(2) {
				width: 48px;
				height: 48px;
			}
			&:nth-child(3) {
				width: 32px;
				height: 32px;
			}
		}
		&:after {
			position: absolute;
			top: 100%;
			left: 0;
			margin: 20px 5px 0;
			width: 100%;
			color: #FFF;
			line-height: 1;
			text-transform: uppercase;
			font-size: 13px;
			opacity: 0;
			transition: 0.3s;
		}
	}
}

.lt-ie10 {
	#nav-multithumb {
		.preview__img {
			display: none;
		}
	}
}

.desktop {
}

.mobile, .tablet {
	.swiper-pagination {
		display: block;
	}
	.swiper-button {
		display: none;
	}
	.preview {
		display: none;
	}
}

/* 3D Container */
.swiper-container-3d {
	-webkit-perspective: 1200px;
	-moz-perspective: 1200px;
	-o-perspective: 1200px;
	perspective: 1200px;
}

.swiper-container-3d .swiper-wrapper,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-cube-shadow {
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	z-index: 10;
}

.swiper-container-3d .swiper-slide-shadow-left {
	background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.swiper-container-3d .swiper-slide-shadow-right {
}

.swiper-container-3d .swiper-slide-shadow-top {
	background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.swiper-container-3d .swiper-slide-shadow-bottom {
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

/* Coverflow */
.swiper-container-coverflow .swiper-wrapper {
	/* Windows 8 IE 10 fix */
	-ms-perspective: 1200px;
}

/* Fade */
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.swiper-container-fade .swiper-slide {
	pointer-events: none;
}

.swiper-container-fade .swiper-slide .swiper-slide {
	pointer-events: none;
}

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
	pointer-events: auto;
}

/* Cube */
.swiper-container-cube {
	overflow: visible;
}

.swiper-container-cube .swiper-slide {
	pointer-events: none;
	visibility: hidden;
	-webkit-transform-origin: 0 0;
	-moz-transform-origin: 0 0;
	-ms-transform-origin: 0 0;
	transform-origin: 0 0;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	backface-visibility: hidden;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.swiper-container-cube.swiper-container-rtl .swiper-slide {
	-webkit-transform-origin: 100% 0;
	-moz-transform-origin: 100% 0;
	-ms-transform-origin: 100% 0;
	transform-origin: 100% 0;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-prev,
.swiper-container-cube .swiper-slide-next + .swiper-slide {
	pointer-events: auto;
	visibility: visible;
}

.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right {
	z-index: 0;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	backface-visibility: hidden;
}

.swiper-container-cube .swiper-cube-shadow {
	position: absolute;
	left: 0;
	bottom: 0px;
	width: 100%;
	height: 100%;
	background: #000;
	opacity: 0.6;
	-webkit-filter: blur(50px);
	filter: blur(50px);
	z-index: 0;
}

/* Scrollbar */
.swiper-scrollbar {
	position: relative;
	-ms-touch-action: none;
}

.swiper-container-horizontal > .swiper-scrollbar {
	position: absolute;
	bottom: 0;
	z-index: 50;
	height: 3px;
	width: 100%;
}

.swiper-container-vertical > .swiper-scrollbar {
	position: absolute;
	right: 3px;
	top: 1%;
	z-index: 50;
	width: 5px;
	height: 98%;
}

.swiper-scrollbar-drag {
	height: 100%;
	width: 100%;
	position: relative;
	background: $brand-primary;
	left: 0;
	top: 0;
}

.swiper-scrollbar-cursor-drag {
	cursor: move;
}

/* Preloader */
.swiper-lazy-preloader {
	width: 42px;
	height: 42px;
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -21px;
	margin-top: -21px;
	z-index: 10;
	-webkit-transform-origin: 50%;
	-moz-transform-origin: 50%;
	transform-origin: 50%;
	-webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
	-moz-animation: swiper-preloader-spin 1s steps(12, end) infinite;
	animation: swiper-preloader-spin 1s steps(12, end) infinite;
}

.swiper-lazy-preloader:after {
	display: block;
	content: "";
	width: 100%;
	height: 100%;
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
	background-position: 50%;
	-webkit-background-size: 100%;
	background-size: 100%;
	background-repeat: no-repeat;
}

.swiper-lazy-preloader-white:after {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

@-webkit-keyframes swiper-preloader-spin {
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes swiper-preloader-spin {
	100% {
		transform: rotate(360deg);
	}
}
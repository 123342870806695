//
// Services
// --------------------------------------------------

.service {
	position: relative;
	display: inline-block;

	img {
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}

	@media (min-width: $screen-md-min) {
		img { border-radius: 5px; }
	}

	.service-desc {
		display: block;
		background: $brand-primary;
		max-width: 320px;
		margin-left: auto;
		margin-right: auto;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		padding: 15px 48px 14px 17px;
		color: $white;
		overflow: hidden;
		transition: .2s ease-in-out;
		position: relative;
		text-align: left;

		&:after {
			content: '\f150';
			font-family: $icon-font-material-design;
			font-size: 24px;
			color: $white;
			opacity: 0;
			transition: opacity .2s ease-in-out, transform .28s ease-in-out;
			@include transform (scale(0));
			position: absolute;
			top: 10px;
			right: 14px;
		}

		&:hover {
			background: darken(saturate(adjust_hue($brand-primary, -0.09523809523813043), 26.343881052230252%), 14.117647058823522%);;

			&:after {
				opacity: 1;
				@include transform (scale(1));
			}
		}

	}

	@media (min-width: $screen-md-min) {
		.service-desc {
			border-radius: 5px;
			position: absolute;
			bottom: 0;
			right: 0;
			left: 0;
		}
	}
}
//
// Icons
// --------------------------------------------------

// Base styles
// --------------------------------------------------

.icon {
	position: relative;
	display: inline-block;
	font-weight: $icon-default-weight;
	font-size: $icon-default-size;
	line-height: $icon-default-line-height;
	font-style: normal;

	&:before {
		display: inline-block;
		position: relative;
	}
}

a.icon {
	transition: .3s;
}

// Icon shapes
// --------------------------------------------------

.icon-rect,
.icon-circle,
.icon-rounded,
.icon-outlined {
	width: $icon-shapes-width;
	height: $icon-shapes-height;
	font-size: $icon-shapes-size;
	line-height: $icon-shapes-line-height;
	text-align: center;
}

.icon-circle {
	border-radius: $icon-circle-border-radius;
}

.icon-rounded {
	border-radius: $icon-rounded-border-radius;
}

.icon-outlined {
	position: relative;

	&, &:after {
		border-radius: 50%;
	}

	&:after {
		position: absolute;
		top: -7px;
		right: -7px;
		bottom: -7px;
		left: -7px;
		content: '';
		border: $icon-outlined-border;
	}
}

// Icon sizes
// --------------------------------------------------

.icon-xxxs {
	font-size: 20px;
	line-height: 20px;
}

.icon-xxs {
	font-size: $icon-xxs-size;
	line-height: $icon-xxs-line-height;

	&.icon-rect,
	&.icon-rounded,
	&.icon-circle,
	&.icon-outlined {
		width: 40px;
		height: 40px;
		line-height: 40px;
		font-size: 18px;
	}

	&.fa-envelope {
		font-size: 23px;
	}
	&.fa-phone {
		font-size: 22px;
	}
}

.icon-xs {
	font-size: $icon-xs-size;
	line-height: $icon-xs-line-height;

	&.icon-rect,
	&.icon-rounded,
	&.icon-circle,
	&.icon-outlined {
		width: 50px;
		height: 50px;
		line-height: 50px;
		font-size: 26px;
	}
}

.icon-sm {
	font-size: $icon-sm-size;
	line-height: $icon-sm-line-height;

	&.icon-rect,
	&.icon-rounded,
	&.icon-circle,
	&.icon-outlined {
		width: 60px;
		height: 60px;
		line-height: 58px;
		font-size: 26px;
	}
}

.icon-lg {
	font-size: $icon-lg-size;
	line-height: $icon-lg-line-height;

	&.icon-rect,
	&.icon-rounded,
	&.icon-circle,
	&.icon-outlined {
		width: 80px;
		height: 80px;
		line-height: 80px;
		font-size: 36px;
	}
}

.icon-lg-1 {
	font-size: $icon-lg-size;
	line-height: $icon-lg-line-height;

	&.icon-rect,
	&.icon-rounded,
	&.icon-circle,
	&.icon-outlined {
		width: 70px;
		height: 70px;
		line-height: 70px;
		font-size: 36px;
	}
}

.icon-xlg {
	font-size: $icon-xlg-size;
	line-height: $icon-xlg-line-height;

	&.icon-rect,
	&.icon-rounded,
	&.icon-circle,
	&.icon-outlined {
		width: 130px;
		height: 130px;
		line-height: 130px;
		font-size: 48px;
	}
}

// Icon Appearance
// --------------------------------------------------

.icon-default {
	color: $icon-default-color;
	border-color: $icon-default-border-color;
}

.icon-darker {
	color: $icon-darker-color;
	border-color: $icon-darker-border-color;
}

.icon-primary {
	color: $icon-primary-color;
	border-color: $icon-primary-border-color;
}

.icon-deluge {
	color: $icon-deluge-color;
	border-color: $icon-deluge-border-color;
}

.icon-picton-blue {
	color: $icon-picton-blue-color;
	border-color: $icon-picton-blue-border-color;
}

.icon-success {
	color: $icon-success-color;
	border-color: $icon-success-border-color;
}

.icon-warning {
	color: $icon-warning-color;
	border-color: $icon-warning-border-color;
}

.icon-danger {
	color: $brand-danger;
	border-color: $brand-danger;
}

.icon-info {
	color: $brand-info;
	border-color: $brand-info;
}

%icon-filled {
	color: $white;
	border: none;
}

.icon-darker-filled {
	@extend %icon-filled;
	background: $gray-darker;
}

.icon-darkest-filled {
	@extend %icon-filled;
	background: $gray-darkest;
}

.icon-gray-light {
	@extend %icon-filled;
	background: $gray-lighter-2;
	color: $gray-dark;
}

.icon-gray-light-2 {
	@extend %icon-filled;
	background: $gray-light;
	color: $gray-dark;
}

.icon-shark-filled {
	@extend %icon-filled;
	@include bg-variant-custom(linear-gradient(45deg, $gray-base 0%, $gray-darker 100%));
}

.icon-mantis-filled {
	@extend %icon-filled;
	@include bg-variant-custom(linear-gradient(45deg, $brand-primary 0%, $brand-dollar-bill 100%));
}

.icon-pink-filled {
	@extend %icon-filled;
	@include bg-variant-custom(linear-gradient(45deg, $brand-deluge 0%, $brand-butterfly-bush 100%));
}

.icon-malibu-filled {
	@extend %icon-filled;
	@include bg-variant-custom(linear-gradient(45deg, $brand-picton-blue 0%, $brand-info 100%));
}

.icon-carrot-filled {
	@extend %icon-filled;
	@include bg-variant-custom(linear-gradient(45deg, $brand-warning 0%, $brand-neon-carrot 100%));
}

.icon-red-filled {
	@extend %icon-filled;
	@include bg-variant-custom(linear-gradient(45deg, $brand-danger 0%, $brand-ku-crimson 100%));
}

.icon-blue-gray-filled {
	@extend %icon-filled;
	@include bg-variant-custom(linear-gradient(-45deg, $brand-deluge 0%, $brand-picton-blue 100%));
}

.icon-gradient:before {
	@supports (-webkit-background-clip: text) and (-webkit-text-fill-color: transparent) {
		transition: color .25s, -webkit-text-fill-color .25s 0s;
		background: linear-gradient(135deg, $brand-primary 0%, $brand-java 100%);
		color: transparent;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
}

// Additional icon sizes for icon fonts
// --------------------------------------------------

.icon {
	&.icon-xs {
		&.mdi-monitor {
			font-size: 28px;
		}

	}

	&.icon-sm {
		&.mdi-monitor {
			font-size: 36px;
		}
		&.mdi-code-brackets,
		&.mdi-clock,
		&.mdi-vector-arrange-below {
			font-size: 45px;
		}

	}

	&.icon-lg {
	}

	&.icon-xl {
	}
}

.icon-rect,
.icon-circle,
.icon-rounded,
.icon-outlined {

	&.mdi-vector-arrange-below,
	&.mdi-share-variant,
	&.mdi-magnify,
	&.mdi-monitor {
		font-size: 36px;
	}
	&.mdi-brush {
		font-size: 38px;
	}
	&.mdi-cube-outline,
	&.mdi-palette {
		font-size: 40px;
	}
	&.mdi-format-bold,
	&.mdi-spellcheck,
	&.mdi-rocket,
	&.mdi-xml {
		font-size: 42px;
	}

	&.icon-xs {  }
	&.icon-sm {  }
	&.icon-lg {  }
	&.icon-lg {  }
	&.icon-xl {  }
}

// Fonts List Icon
// --------------------------------------------------

a {
	&.icon-rect,
	&.icon-circle,
	&.icon-outlined,
	&.icon-rounded {
		&.fa-facebook:hover {
			color: $white;
			background: #0956b5;
		}

		&.fa-twitter:hover {
			color: $white;
			background: #28d4f9;
		}

		&.fa-google-plus:hover {
			color: $white;
			background: #e3401d;
		}

		&.fa-linkedin:hover {
			color: $white;
			background: #0E7EB5;
		}

		&.fa-500px:hover {
			color: $white;
			background: #bebebe;
		}

		&.fa-rss:hover {
			color: $white;
			background: #f6981d;
		}

		&.fa-behance:hover {
			background: #64aae1;
			color: $white;
		}

	}
}

// Fonts List Icon
// --------------------------------------------------

.fontawesome-icon-list {
	text-align: left;

	.icon-list-item {
		i {
			padding-right: 15px;
			font-size: 22px;
			line-height: 22px;
			float: left;
		}

		padding: 15px 0 15px 20px;
		font-size: 18px;
		line-height: 18px;
		border-radius: $border-radius-small;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		&:hover {
			background: $brand-primary;
			color: $white;
			box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);

			i {
				@include transform (scale(1.5));
			}
		}
	}
}

.icon-list {
	.icon {
		color: $brand-primary;
		font-size: 30px;
		line-height: 30px;
	}

	.unit-body {
		width: 100%;
		text-align: left;
	}

	.unit {
		transition: 0.35s all ease;
		padding: 10px;
		border-radius: 3px;

		&:hover {
			background: $brand-java;
			box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);

			p, .icon { color: $white; }

			.icon-gradient:before {
				color: $white;
				-webkit-text-fill-color: currentcolor;
			}
		}
	}
}

// Static Icons
// --------------------------------------------------

.icon-doctor,
.icon-emergency,
.icon-medical-car,
.icon-pills {
	&-md,
	&-xl {
		> span {
			content: '';
			display: inline-block;
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-position: center;
		}
	}
}

.icon-doctor-md > span { background-image: url("../images/icons/icon-doctor-md.png"); }
.icon-doctor-xl > span { background-image: url("../images/icons/icon-doctor-xl.png"); }
.icon-medical-car-md > span { background-image: url("../images/icons/icon-medical-car-md.png"); }
.icon-medical-car-xl > span { background-image: url("../images/icons/icon-medical-car-xl.png"); }
.icon-pills-md > span { background-image: url("../images/icons/icon-pills-md.png"); }
.icon-pills-xl > span { background-image: url("../images/icons/icon-pills-xl.png"); }

.icon-emergency-md > span {
	background-image: url("../images/icons/icon-emergency.png");
	height: 21px;
	width: 24px;
}


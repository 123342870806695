//
// Flot Charts
// --------------------------------------------------

// Shared Flot and Sparkline tooltip
//

.jqstooltip,
#flotTip {
  color: #fff;
  width: auto !important;
  height: auto !important;
  padding: 2px 6px !important;
  background-color: rgba(0, 0, 0, 0.7) !important;
  border: 0 !important;
  border-radius: 3px;
}

.flot-x-axis .flot-tick-label.tickLabel {
  color: #AAA;
}

.flot-y-axis .flot-tick-label.tickLabel {
  color: #AAA;
  padding-top: 6px;
  padding-left: 14px;
}

td.legendColorBox {
  padding: 5px;
}

//
// RD Navbar Default First level of Menu
// --------------------------------------------------

%rd-navbar-default-menu-first-lvl{
  .rd-navbar-nav > li > .rd-navbar-submenu-toggle:after{
    content: '\f078';
    font-family:'FontAwesome';
    font-size: 8px;
    line-height: 8px;
    color: $white;
    position: relative;
    top: -1px;
    right: 11px;

    @media (min-width: $screen-lg-min) {
    	right: 19px;
    }
  }

  .rd-navbar-nav {
    @include clearfix;
    > li {
      float: left;
      position: relative;
      transition: 180ms ease-in-out;


      &.active,
      &.focus,
      &:hover{
        background: $brand-java;
      }

      > a {
        @extend %rd-navbar-default-link-font;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        letter-spacing: 0.06em;
        padding: 18px 12px;

        @media (min-width: $screen-lg-min) {
          padding: 18px 24px;
        }

        > .rd-navbar-label {
          position: absolute;
          margin: -10px 0 0 -15px;
        }
      }

      &.rd-navbar--has-megamenu {
        position: static;
      }

      + li {
        margin-left: 0px;
      }
    }

    li.focus, li.opened {
      > .rd-navbar-dropdown, > .rd-navbar-megamenu {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .rd-navbar-search{

  }
}

//
// Dark Theme
// --------------------------------------------------

%rd-navbar-default-menu-first-lvl-dark {
  .rd-navbar-nav {
    > li {
      a {
        color: $rd-navbar-dark-menu-first-lvl-list-item-link-color;
      }
    }
  }
}

//
// Light Theme
// --------------------------------------------------

%rd-navbar-default-menu-first-lvl-light {
  .rd-navbar-nav {
    > li {
      a {
        color: $rd-navbar-light-menu-first-lvl-list-item-link-color;
      }
    }
  }
}